document.addEventListener("turbolinks:load", () => {
    $('[data-toggle="tooltip"]').tooltip();
});

$(function() {
    $( ".select2" ).select2({
        theme: "bootstrap",
        language: 'es-ES',
        width: '100%'
    });
    $( ".select2-tags" ).select2({
        theme: "bootstrap",
        tags: true,
        language: 'es-ES',
        width: '100%',
        tokenSeparators: [',', ' ']
    });
    $('.horizontal-carousel').each(function() {
        let flick = new Flickity(this, {
            freeScroll: true,
            contain: true,
            // disable previous & next buttons and dots
            //prevNextButtons: false,
            pageDots: false,
            groupCells: true,
            wrapAround: $(this).find('.flickity-cell').length > 6
        });
        let selectedIndex = $(this).data('selected-index');
        if (selectedIndex) {
            flick.selectCell(selectedIndex, false, true);
        }
    });
    // Fix menu dropdown events
    var $nav = $('.navbar .navbar-nav');
    var $menuItems = $nav.find('.dropdown-menu');
    $menuItems.addClass('invisible');
    $nav.find('.dropdown-toggle').trigger('click');
    setTimeout(function(){ $menuItems.removeClass('invisible'); }, 1000);
});