$(function() {
    // Bind events on modal show with correct parent
    $(document).on('shown.bs.modal', function(e) {
        var $this = $(e.target);
        $this.find('.select2').each(function(index, element) {
            var options = select2options(element);
            options.dropdownParent = $this.find('.modal-dialog');
            select2(element, options);
        });
    });

    reloadSelect2();
});

window.reloadSelect2 = function() {
    // Default input
    $('input.select2:not(.select2-applied), select.select2:not(.select2-applied)').each(function(index, element) {
        select2(element);
    });
};

window.select2options = function(select) {
    var $select = $(select);
    var opts = {
        theme: 'bootstrap',
        language: 'es-ES',
        width: '100%',
        allowClear: !!$select.data('allow_clear'),
        maximumSelectionLength: $select.data('valuecount') || 0
    };

    if ($select.hasClass('async')) {
        var ajaxOptions = {
            ajax: {
                dataType: 'json',
                delay: 250,
                data: function(params) {
                    return { q: params.term, page: params.page || 1 };
                },
                url: function() {
                    return $(this).data('url');
                },
                processResults: function(data, params) {
                    if (!!$select.data('include-blank') && data.results.length > 0) {
                        data.results.unshift({ text: 'Sin seleccionar' });
                    }
                    return data;
                },
                cache: true
            }
        };
        opts = Object.assign(opts, ajaxOptions);
    }

    if ($select.hasClass('no-search')) {
        opts.dropdownCssClass = 'no-search';
    }

    return opts;
};

window.select2 = function(element, options) {
    var $element = $(element);
    // Ensure we do not apply again to already applied select or container
    if (!$element.hasClass('select2-applied') && !$element.hasClass('select2-container')) {
        options = options || select2options(element);
        $element.addClass('select2-applied');
        $element.select2(options);
        if ($element.hasClass('async') && !!$element.data('selected')) {
            // Execute in next thread to await for listeners
            setTimeout(function() {
                setSelect2Value($element, $element.data('selected'));
            }, 1);
        }
    }
};