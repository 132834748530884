$(function() {
    $('.store-search #province_id').on('change', function () {
        $('.store-search #city_id').val('');
        showCurrentLocation();
        reloadStoreList();
        reloadCityList();
    })
    $('.store-search #city_id').on('change', function () {
        showCurrentLocation();
        reloadStoreList();
    });
    $('#reset-filter-btn').on('click', function () {
        $('#search-modal #province_id').val('').trigger('change');
    });
});

function getURL(path, params) {
    const url = new URL(path, window.location.origin);
    const searchParams = new URLSearchParams(params);
    url.search = searchParams.toString();
    return url.toString();
}

function reloadStoreList() {
    const province = getSelectedProvince();
    const city = getSelectedCity();
    const url = getURL('/paginated_stores', { province: province, city: city })
    const $grid = $('#stores-grid');
    $grid.html(LOADING_HTML);
    loadAsyncContent('#stores-grid .load-more-spinner', url, true);
}

function reloadCityList() {
    const province = getSelectedProvince();
    const $citySelect = $('.store-search #city_id');
    // Disable selector while loading
    $citySelect.attr('disabled', 'disabled')
    const url = getURL('/beruby/cities', { province: province });
    $.ajax({
        url: url,
        method: 'GET',
        dataType: 'json',
        success: function(response) {
            updateSelectOptions($citySelect, response);
        },
        error: function(xhr, status, error) {
            console.error('Error fetching data:', status, error);
            updateSelectOptions($citySelect, []);
        }
    });
}

function getSelectedProvince() {
    return $('.store-search #province_id').val();
}

function getSelectedCity() {
    return $('.store-search #city_id').val();
}
function showCurrentLocation() {
    const province = getSelectedProvince();
    const city = getSelectedCity();
    const provincePresent = province !== undefined && province !== '';
    const cityPresent = city !== undefined && city !== '';
    let display = 'todo el territorio';
    if (provincePresent) {
        display = cityPresent ? `${city} (${province})` : province
    }
    $('.current-location-info .location-name').text(display);
}

function updateSelectOptions(select, data) {
    const $select = $(select);

    // Clear current options
    $select.empty();

    // Include empty
    $select.append('<option></option>');

    const hasResults = data.length > 0;
    if (hasResults) {
        // Add new options one by one
        data.forEach(function(item) {
            $select.append(new Option(item.name, item.id, false, false));
        });
        $select.removeAttr('disabled')
    } else {
        $select.attr('disabled', 'disabled')
    }
    $select.toggleClass('disabled', !hasResults);
    // Refresh select2 to show changes
    $select.trigger('select2:select');
}